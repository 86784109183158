$black : #00212A;

.navbar{
    .logo{
        height: 60px;
    }
}

.main-content{
    padding: 40px 20px;
}

h2, h3, h4, h5{
    font-family: "Arial Black", "Arial Bold", Gadget, sans-serif;
    color: $black;
}

.footer{
    background-color: $black;
    color: #fff;
    padding: 20px;
    text-align: right;
    position: absolute;
    bottom: 0;
    width: 100%;
    a{
        color: #fff;
        &:hover{
            color: #fff !important;
            font-weight: 600;
        }
    }
    .logo{
        height: 60px;
    }

}
html{
    position: relative;
    min-height: 100%;
}